@import './cssreset.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import './components/header/header.css';
@import './components/section/section.css';
@import './components/testimonial/testimonials.css';
@import './components/footer/footer.css';
@import './components/modal/modal.css';
@import './components/about/aboutUs.css';

:root {
  --transition-speed: 0.3s;
  --transition-timing: cubic-bezier(0.4, 0, 0.2, 1);
  

  --mobile: 480px;
  --tablet: 768px;
  --laptop: 1080px;
  --desktop: 1440px;
  
  --primary-color: #FFB46B;
  --background-color: #282626;
  --text-color: white;
  --card-background: #D9D9D9;
  
  --spacing-xs: 4px;
  --spacing-sm: 8px;
  --spacing-md: 16px;
  --spacing-lg: 24px;
  --spacing-xl: 32px;
  
  --font-xs: 12px;
  --font-sm: 14px;
  --font-md: 16px;
  --font-lg: 20px;
  --font-xl: 24px;
  --font-2xl: 32px;
}

body {
  font-family: Montserrat;
  background: var(--background-color);
  color: var(--text-color);
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  overflow-x: hidden;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity var(--transition-speed) var(--transition-timing);
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity var(--transition-speed) var(--transition-timing);
}

a, input, select, textarea {
  transition: all var(--transition-speed) var(--transition-timing);
}

a:hover {
  transform: translateY(-2px);
}

hr {
  border: none;
  background-color: #939393;
  width: 100%;
  height: 1px;
  transition: background-color var(--transition-speed) var(--transition-timing);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 0.5s var(--transition-timing);
}

.container {
  width: 100%;
  max-width: var(--desktop);
  margin: 0 auto;
  padding: 0 var(--spacing-md);
}

@media (max-width: 768px) {
  :root {
    --spacing-lg: 20px;
    --spacing-xl: 28px;
    --font-lg: 18px;
    --font-xl: 22px;
    --font-2xl: 28px;
  }
}

@media (max-width: 480px) {
  :root {
    --spacing-lg: 16px;
    --spacing-xl: 24px;
    --font-lg: 16px;
    --font-xl: 20px;
    --font-2xl: 24px;
  }
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 6px;
  border: 3px solid var(--background-color);
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--primary-color) transparent;
}

