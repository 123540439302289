.qr-modal--container {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

.qr-modal--container.active--modal {
  display: flex;
  opacity: 1;
}

.qr-modal--content {
  position: relative;
  width: 90%;
  max-width: 400px;
  margin: auto;
  background: linear-gradient(145deg, #2a2828, #1f1d1d);
  border-radius: 24px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4),
              0 0 0 1px rgba(255, 180, 107, 0.1);
  transform: translateY(-20px);
  transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
  padding: 2rem;
}

.qr-modal--content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    circle at 50% 0%,
    rgba(255, 180, 107, 0.15) 0%,
    rgba(255, 180, 107, 0.1) 20%,
    rgba(255, 180, 107, 0.05) 40%,
    transparent 100%
  );
  opacity: 0.5;
  pointer-events: none;
}

.qr-modal--content::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(
    circle at center,
    rgba(255, 180, 107, 0.1) 0%,
    transparent 70%
  );
  animation: rotate 20s linear infinite;
  pointer-events: none;
  opacity: 0.7;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.qr-modal--container.active--modal .qr-modal--content {
  transform: translateY(0);
}

.qr-modal--cross {
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: #FFB46B;
  color: white;
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  font-size: 20px;
  box-shadow: 0 0 15px rgba(255, 180, 107, 0.3);
}

.qr-modal--cross:hover {
  background-color: #ff9f3d;
  transform: rotate(90deg) scale(1.1);
  box-shadow: 0 0 25px rgba(255, 180, 107, 0.5);
}

.qr-modal__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  text-align: center;
  position: relative;
}

.qr-modal__title {
  font-size: 28px;
  font-weight: 600;
  color: #fff;
  margin: 0;
  text-shadow: 0 0 10px rgba(255, 180, 107, 0.3),
               0 0 20px rgba(255, 180, 107, 0.2),
               0 0 30px rgba(255, 180, 107, 0.1);
  position: relative;
  z-index: 1;
  letter-spacing: 0.5px;
}

.qr-modal__qr-container {
  background: white;
  padding: 1.5rem;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2),
              0 0 0 1px rgba(255, 180, 107, 0.2),
              0 0 30px rgba(255, 180, 107, 0.1);
  position: relative;
  z-index: 1;
  transition: all 0.3s ease;
}


@keyframes borderGlow {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.qr-modal__qr-container:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3),
              0 0 0 1px rgba(255, 180, 107, 0.3),
              0 0 40px rgba(255, 180, 107, 0.2);
}

.qr-modal__qr-code {
  display: block;
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1));
}

.qr-modal__description {
  color: #cccccc;
  font-size: 16px;
  line-height: 1.6;
  margin: 0;
  position: relative;
  z-index: 1;
  max-width: 80%;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.qr-modal__span--number {
  display: block;
  margin-top: 1.5rem;
  font-size: 1.4rem;
  color: #FFB46B;
  font-weight: 600;
  text-shadow: 0 0 10px rgba(255, 180, 107, 0.3),
               0 0 20px rgba(255, 180, 107, 0.2),
               0 0 30px rgba(255, 180, 107, 0.1);
  position: relative;
  z-index: 1;
  transition: all 0.3s ease;
  letter-spacing: 1px;
}

.qr-modal__span--number:hover {
  transform: scale(1.05);
  text-shadow: 0 0 15px rgba(255, 180, 107, 0.4),
               0 0 25px rgba(255, 180, 107, 0.3),
               0 0 35px rgba(255, 180, 107, 0.2);
}

@media (max-width: 768px) {
  .qr-modal--content {
    width: 95%;
    padding: 1.5rem;
  }

  .qr-modal__title {
    font-size: 24px;
  }

  .qr-modal__description {
    font-size: 14px;
    max-width: 90%;
  }

  .qr-modal__span--number {
    font-size: 1.2rem;
  }
} 