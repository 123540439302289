.about-us {
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.about-us.visible {
  opacity: 1;
}

.about-us__title--container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
}

.about-us__title {
  width: 500px;
  font-weight: 400;
  font-size: 48px;
  line-height: 58px;
  opacity: 0;
  animation: title-fade-in 2s ease-in-out forwards;
}

.about-us__title::before {
  position: absolute;
  content: "";
  text-transform: uppercase;
  height: 78px;
  width: 450px;
  top: -40px;
  transform: translate(calc(-50% + 78px), -50%);
  background: transparent;
  background-image: url('../../icons/ABOUT\ US.svg');
  background-repeat: no-repeat;
  animation: title-underline-slide 2s ease-in-out forwards 2s; 
}

@keyframes title-fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes title-underline-slide {
  from { transform: translateX(-100%); } 
  to { transform: translateX(calc(-50% + 78px)); }
}

.about-us__description {
  display: flex;
  justify-content: space-between;
  padding: 0 210px;
  opacity: 0;
  animation: description-fade-in 1s ease-in-out forwards;
}

.about-us__list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 70px;
  animation: list-items-fade-in 1s ease-in-out forwards, list-items-bounce 1s ease-in-out forwards 0.5s;
}

.about-us__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.75px;
  text-align: left;
}

.about-us--icon {
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #FFB46B;
  justify-content: center;
  align-items: center;
  align-self: center;
  animation: icon-pulse 1s ease-in-out infinite; 
}

.about-us--icon-inside {
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 5px #C09A75 solid;
}

@keyframes icon-pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

@keyframes list-items-fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes list-items-bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); } 
}

@media (max-width: 1200px) {
  .about-us {
    padding-top: 100px;
  }
  
  .about-us__title {
    width: 400px;
    font-size: 40px;
    line-height: 50px;
  }
  
  .about-us__description {
    padding: 0 150px;
  }
}

@media (max-width: 768px) {
  .about-us {
    padding-top: 80px;
  }
  
  .about-us__title--container {
    flex-direction: column;
  }
  
  .about-us__title {
    width: 100%;
    font-size: 32px;
    line-height: 40px;
  }
  
  .about-us__description {
    flex-direction: column;
    padding: 0 50px;
  }
  
  .about-us__list {
    gap: 50px;
  }
  
  .about-us__item {
    flex-direction: column;
    gap: 20px;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .about-us {
    padding-top: 60px;
  }
  
  .about-us__title {
    font-size: 24px;
    line-height: 32px;
  }

  .about-us__title::before {
    width: 300px;
    background-size: contain;

    top: -50px;
    right: 0;
  }
  
  .about-us__description {
    padding: 0 20px;

    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  
  .about-us__item {
    font-size: 16px;
    line-height: 24px;
  }
  
  .about-us--icon {
    width: 40px;
    height: 40px;
  }
  
  .about-us--icon-inside {
    width: 20px;
    height: 20px;
    border-width: 4px;
  }
}

@keyframes title-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes description-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}