* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body, html {
  font-family: Arial, sans-serif;
  overflow-x: hidden;
}

.header {
  padding: 20px 50px;
  height: 92px;
  background-color: #000000;
  color: #FFFFFFCC;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header__group {
  display: flex;
  gap: 25px;
  align-items: center;
}

.header__list {
  display: flex;
  gap: 40px;
  list-style: none;
}

.header__span {
  font-size: 20px;
  font-weight: 500;
  text-align: left;
}

.header__link {
  color: white;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  cursor: pointer;
  text-decoration: none;
}

.header__link:hover {
  color: #FFB46B;
  transition: 0.3s;
}

.header__buttons {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  width: 100px;
  border: 2px solid white;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
}


.header__buttons::after {
  content: "";
  position: absolute;
  background-color: white;
  border-radius: 13px;
  width: 100%;
  height: 50%;
  transition: transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  z-index: 0;
  overflow: visible;

}

.header__buttons.ukr-active::after {
  transform: translateY(0);
}

.header__buttons.eng-active::after {
  transform: translateY(100%);
}


.header__button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 100%;
  height: 30px;
  position: relative;
  z-index: 1;
  transition: color 0.3s ease;
}

.header__buttons.ukr-active .header__button--ukr {
  color: #000000;
}

.header__buttons.eng-active .header__button--eng {
  color: #000000;
}

.header__buttons.ukr-active .header__button--eng,
.header__buttons.eng-active .header__button--ukr {
  color: white;
}


.img__banner {
  height: 100vh;
  filter: brightness(0.4);
  position: relative;
  background-size: cover;
  background-position: center;

  overflow: hidden;

  animation: fadeIn 1s ease-out forwards;
}

.img__span {
  height: 86px;
  font-size: 82.15px;
  font-weight: 600;
  text-align: center;
  position: absolute;
  bottom: 50%;
  right: 50%;
  color: white;
  transform: translate(50%, 50%);
}

.img__button {
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 500;
  line-height: 21.94px;
  
  cursor: pointer;

  width: 367px;
  height: 69px;

  justify-content: center;
  align-items: center;
  text-align: center;
  
  position: absolute;
  bottom: 10%;
  right: 50%;
  color: white;
  background: transparent;
  border: 2px solid white;
  transform: translate(50%, 50%);
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.img__button:hover {
  transform: translate(50%, 50%) scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.img__button:active {
  transform: translate(50%, 50%) scale(0.95);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.img__banner-slice {
  height: 100vh;
  width: 25%;
  object-fit: cover;
  overflow: hidden;
  
  scale: 1.1;
}


.activeStatus {
  background-color: white;
  color: #000000;
  border-radius: 16px;
  width: 100%;
}

.header__button--eng::before,
.header__button--ukr::before {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.header__button--eng::before {
  background-image: url('../../icons/eng.svg');
  background-size: cover;
}

.header__button--ukr::before {
  background-image: url('../../icons/ukr.svg');
  background-size: cover;
}

@media (max-width: 1080px) {
  .header {
    padding: 20px 30px;
    height: auto;
    flex-direction: column;
  }

  .header__group {
    gap: 15px;
  }

  .header__list {
    gap: 30px;
  }

  .header__span {
    font-size: 18px;
  }

  .header__link {
    font-size: 14px;
  }

  .img__span {
    font-size: 60px;
    line-height: 65px;
  }
}

@media (max-width: 768px) {
  .header {
    padding: 15px 20px;
  }

  .header__bar {
    width: 100%;
  }

  .header__list {
    column-count: 2;
  }

  .header__span {
    font-size: 16px;
  }

  .header__link {
    font-size: 12px;
  }

  .img__span {
    font-size: 50px;
    line-height: 55px;
  }

  .img__button {
    width: 80%;
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .header {
    padding: 10px 15px;
  }

  .header__bar {
    display: flex;
    flex-direction: column;
    align-items: self-start;
    gap: 10px;
  }

  .header__group {
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .header__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 50px;
    align-items: center;
    justify-content: center;
    align-self: center;
    justify-self: center; 
  }

  .header__item {
    display: flex;
  }

  .header__span {
    font-size: 16px;
  }

  .header__link {
    font-size: 12px;
  }

  .img__span {
    font-size: 40px;
    line-height: 45px;
  }

  .img__button {
    width: 90%;
    font-size: 16px;
  }
}
