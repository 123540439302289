* {
  box-sizing: border-box;
}

.section {
  padding: var(--spacing-xl) var(--spacing-lg) 0;
  width: 100%;
}

.section__title-name {
  display: flex;
  flex-direction: row;
  min-width: 200px;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--spacing-xl);
}

.section__span {
  font-size: var(--font-2xl);
  font-weight: 500;
  line-height: 1.5;
  margin: 0 var(--spacing-xl);
  display: block;
  white-space: nowrap;
  text-align: center;
}

.section__span--color-yel {
  color: var(--primary-color);
}

.cards__list {
  list-style: none;
  padding-top: var(--spacing-lg);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: var(--spacing-lg);
  justify-items: center;
  max-width: var(--desktop);
  margin: 0 auto;
}

.card {
  background-color: var(--card-background);
  border-radius: 10px;
  width: 100%;
  max-width: 345px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: var(--spacing-sm);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  color: black;
}

.card:hover {
  transform: translateY(-8px);
}

.card__photo--container {
  width: 100%;
  aspect-ratio: 16/9;
  overflow: hidden;
  position: relative;
  background-color: #f0f0f0;
}

.card__main-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease;
  opacity: 0;
}

.card__main-img.loaded {
  opacity: 1;
}

.card:hover .card__main-img.loaded {
  transform: scale(1.05);
}

.card__line {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-sm) var(--spacing-md);
  font-weight: bold;
  font-size: var(--font-lg);
  background-color: var(--primary-color);
}

.card__line--group {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.card__line--group img {
  width: 24px;
  height: 24px;
}

.card__span {
  font-size: var(--font-md);
}

.card__description {
  padding: var(--spacing-md);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.card__name {
  font-size: var(--font-xl);
  margin: var(--spacing-sm) 0;
  color: #333;
  text-align: center;
}

.card__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.card__item {
  padding: var(--spacing-xs) 0;
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  font-size: var(--font-sm);
}

.card__icon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.card__button {
  width: calc(100% - var(--spacing-lg));
  max-width: 295px;
  height: 42px;
  background-color: transparent;
  font-family: Montserrat;
  font-size: var(--font-sm);
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  border: 1px solid black;
  margin: var(--spacing-sm) 0;
  cursor: pointer;
  transition: all 0.3s ease;
}

.card__button:hover {
  background-color: black;
  color: white;
}

.card__photo--container::before {
  display: none;
}

.card__main-img.loaded + .card__photo--container::before {
  display: none;
}

.emblems {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.emblems__list {
  display: flex;
  flex-direction: row;
  gap: 140px;
}

.emblems__item {
  display: flex;
  flex-direction: column;
  font-size: 17px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.5825892686843872px;
  text-align: center;
  gap: 40px;

  animation-name: shake;
  animation-duration: 0.3s;
}

.emblems__container--icon {
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-self: center;
  background-color: transparent;
  border: #FFB46B solid 4px;
  border-radius: 50%;
}

.emblems__subtitle {
  padding-top: 20px;
  padding-bottom: 75px;
}

.icon--emb {
  width: 50px;
  height: 50px;
  margin: auto;
}

.marks {
  display: flex;
  margin-top: 110px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 86px;
}

.marks__list {
  display: flex;
  flex-direction: row;
  gap: 110px;
}

.marks__item {
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: relative;
}

.marks__line--group {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.marks__line {
  width: 275px;
  height: 15px;
  background-color: #D9D9D9;
  border-radius: 100px;
  position: relative;
}

.marks__line::after {
  border-radius: 100px;
  background-color: #7FD988C4;
  height: 15px;
  width: 94%;
  animation-duration: 3s;
  animation-name: slidein;
  position: absolute;
  content: "";
  box-shadow: 0 0 15px 5px rgba(127, 217, 136, 0.7);
}

@keyframes slidein {
  from {
    width: 0%;
  }
  to {
    width: 94%;
  }
}

hr {
  animation-duration: 3s;
  animation-name: slidein;
}

@media (max-width: 1080px) {
  .section {
    padding: var(--spacing-lg) var(--spacing-md) 0;
  }
  
  .cards__list {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: var(--spacing-md);
  }
}

@media (max-width: 768px) {
  .section__span {
    font-size: var(--font-xl);
    margin: 0 var(--spacing-lg);
  }
  
  .cards__list {
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    gap: var(--spacing-md);
  }
  
  .card {
    max-width: 320px;
  }
  
  .card__name {
    font-size: var(--font-lg);
  }
}

@media (max-width: 480px) {
  .section {
    padding: var(--spacing-md) var(--spacing-sm) 0;
  }
  
  .section__span {
    font-size: var(--font-lg);
    margin: 0 var(--spacing-md);
  }
  
  .cards__list {
    grid-template-columns: 1fr;
    gap: var(--spacing-md);
  }
  
  .card {
    max-width: 100%;
  }
  
  .card__button {
    width: calc(100% - var(--spacing-md));
  }
}

@keyframes shake {
  0% { -webkit-transform: translate(2px, 1px) rotate(0deg); }
  10% { -webkit-transform: translate(-1px, -2px) rotate(-1deg); }
  20% { -webkit-transform: translate(-3px, 0px) rotate(1deg); }
  30% { -webkit-transform: translate(0px, 2px) rotate(0deg); }
  40% { -webkit-transform: translate(1px, -1px) rotate(1deg); }
  50% { -webkit-transform: translate(-1px, 2px) rotate(-1deg); }
  60% { -webkit-transform: translate(-3px, 1px) rotate(0deg); }
  70% { -webkit-transform: translate(2px, 1px) rotate(-1deg); }
  80% { -webkit-transform: translate(-1px, -1px) rotate(1deg); }
  90% { -webkit-transform: translate(2px, 2px) rotate(0deg); }
  100% { -webkit-transform: translate(1px, -2px) rotate(-1deg); }
  }
  .shake:hover,
  .shake:focus {
    cursor: pointer;
  -webkit-animation-name: shake;
  -webkit-animation-duration: 0.8s;
  -webkit-transform-origin:50% 50%;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  }