.modal--container {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  overflow-y: auto;
  padding: 20px;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

.modal--container.active--modal {
  display: flex;
  opacity: 1;
}

.modal--content {
  position: relative;
  width: 95%;
  max-width: 1400px;
  margin: auto;
  background-color: #282626;
  border-radius: 24px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
  transform: translateY(-20px);
  transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.modal--container.active--modal .modal--content {
  transform: translateY(0);
}

.modal {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  padding: 3rem;
  min-height: 0;
}

.modal--cross {
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: #FFB46B;
  color: white;
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.modal--cross:hover {
  background-color: #ff9f3d;
  transform: rotate(90deg);
  box-shadow: 0 4px 12px rgba(255, 180, 107, 0.4);
}

.modal__photos--container {
  flex: 1;
  max-width: 55%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: sticky;
  top: 3rem;
  height: fit-content;
}

.modal__photo--main {
  position: relative;
  width: 100%;
  aspect-ratio: 4/3;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1a1a1a;
}

.modal__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  animation: fadeIn 0.5s ease-out forwards;
}

.modal__photo-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, rgba(0,0,0,0.8), transparent);
  padding: 1.5rem;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
}

.modal__photo-number {
  background: rgba(255, 180, 107, 0.9);
  padding: 6px 16px;
  border-radius: 20px;
  font-weight: 600;
  color: #282626;
  font-size: 16px;
}

.modal__button--click {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 180, 107, 0.95);
  color: white;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 2;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  font-size: 24px;
  outline: none;
}

.modal__button--click:focus-visible {
  box-shadow: 0 0 0 3px rgba(255, 180, 107, 0.5);
  transform: translateY(-50%) scale(1.1);
}

.modal__button--click:hover {
  background-color: #ff9f3d;
  transform: translateY(-50%) scale(1.1);
  box-shadow: 0 4px 12px rgba(255, 180, 107, 0.4);
}

.modal__button--click:disabled {
  background-color: rgba(78, 73, 73, 0.5);
  cursor: not-allowed;
  transform: translateY(-50%);
}

.modal__button--click.prev {
  left: 20px;
}

.modal__button--click.next {
  right: 20px;
}

.photos__list {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding: 5px;
  max-height: none;
  overflow: visible;
}

.photos__item {
  width: 100px;
  height: 75px;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: 2px solid transparent;
  position: relative;
  flex-shrink: 0;
  outline: none;
}

.photos__item:focus-visible {
  box-shadow: 0 0 0 3px rgba(255, 180, 107, 0.5);
  transform: scale(1.05);
}

.photos__item.active {
  border-color: #FFB46B;
}

.photos__item.active::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 180, 107, 0.2);
}

.photo__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modal__description {
  flex: 1;
  max-width: 45%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow-y: auto;
  padding-right: 1rem;
  max-height: 700px;
}

.modal__description::-webkit-scrollbar {
  width: 8px;
}

.modal__description::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
}

.modal__description::-webkit-scrollbar-thumb {
  background-color: rgba(255, 180, 107, 0.5);
  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 0.05);
}

.modal__description::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 180, 107, 0.7);
}

.modal__title {
  font-size: 32px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0.5rem;
  position: sticky;
  top: 0;
  background: #282626;
  z-index: 1;
}

.modal__subtitle {
  font-size: 20px;
  color: #FFB46B;
  margin-bottom: 1rem;
}

.modal__text-description {
  line-height: 1.8;
  color: #cccccc;
  font-size: 16px;
}

.modal__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 1.2rem;
  list-style: none;
  padding: 0;
}

.modal__item {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  color: #cccccc;
  font-size: 15px;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.modal__item:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-2px);
  border-color: rgba(255, 180, 107, 0.2);
}

.modal__item.check::before {
  content: "✓";
  color: #FFB46B;
  font-weight: bold;
  font-size: 18px;
}

.modal__note {
  font-size: 18px;
  color: #cccccc;
  padding: 1.5rem;
  background: rgba(255, 180, 107, 0.1);
  border-radius: 12px;
  border: 1px solid rgba(255, 180, 107, 0.2);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.modal__booking-link {
  display: block;
  text-decoration: none;
  margin: 1rem 0;
}

.modal__booking-button {
  width: 85%;
  padding: 1rem 2rem;
  background: linear-gradient(145deg, #FFB46B, #ff9f3d);
  border: none;
  border-radius: 12px;
  color: #282626;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 4px 15px rgba(255, 180, 107, 0.3);
  display: flex;
  justify-content: center;
  align-items: center; 
  align-self: center;
  justify-self: center;
  overflow: hidden;
}

.modal__booking-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: 0.5s;
}

.modal__booking-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(255, 180, 107, 0.4);
}

.modal__booking-button:hover::before {
  left: 100%;
}

.modal__booking-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 10px rgba(255, 180, 107, 0.3);
}

.success {
  color: #FFB46B;
  font-weight: 600;
}

.wrong {
  color: #ff6b6b;
  font-weight: 600;
}

@media (max-width: 1024px) {
  .modal {
    padding: 2.5rem;
    gap: 2.5rem;
  }

  .modal__photos--container,
  .modal__description {
    max-width: 100%;
  }

  .modal__photo--main {
    aspect-ratio: 16/9;
  }
}

@media (max-width: 768px) {
  .modal--container {
    padding: 0;
    background-color: #282626;
  }

  .modal--content {
    width: 100%;
    margin: 0;
    border-radius: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    transform: none;
  }

  .modal {
    flex-direction: column;
    padding: 1rem;
    gap: 1.5rem;
    height: 100%;
    overflow-y: auto;
  }

  .modal__photos--container {
    max-width: 100%;
    position: relative;
    top: 0;
    max-height: max-content;
  }

  .modal__photo--main {
    position: relative; 
    aspect-ratio: 4/3;
    border-radius: 12px;
  }

  .modal__button--click {
    width: 40px;
    height: 40px;
    font-size: 20px;
    background-color: rgba(255, 180, 107, 0.85);
  }

  .modal__button--click.prev {
    left: 10px;
  }

  .modal__button--click.next {
    right: 10px;
  }

  .modal__description {
    max-width: 100%;
    max-height: none;
    padding-right: 0;
    order: 1;
    gap: 1.5rem;
  }

  .modal__title {
    font-size: 24px;
    margin-bottom: 1rem;
    position: sticky;
    top: -2px;
    background: #282626;
    padding: 1rem 0;
    z-index: 1;
  }

  .modal__subtitle {
    font-size: 18px;
    margin-bottom: 0.8rem;
  }

  .modal__text-description {
    font-size: 15px;
    line-height: 1.6;
  }

  .modal__list {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.8rem;
  }

  .modal__item {
    font-size: 14px;
    padding: 0.8rem;
  }

  .modal__note {
    font-size: 16px;
    padding: 1rem;
  }

  .photos__list {
    justify-content: flex-start;
    gap: 8px;
    padding: 0.5rem 0;
  }

  .photos__item {
    width: 80px;
    height: 60px;
  }

  .modal--cross {
    right: 10px;
    top: 10px;
    width: 36px;
    height: 36px;
    font-size: 24px;
  }

  .modal__booking-button {
    padding: 0.8rem 1.5rem;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .modal {
    padding: 0.8rem;
  }

  .modal__list {
    grid-template-columns: 1fr;
  }

  .modal__item {
    padding: 0.7rem;
  }

  .photos__item {
    width: 70px;
    height: 52px;
  }

  .modal__button--click {
    width: 36px;
    height: 36px;
    font-size: 18px;
  }

  .modal__title {
    font-size: 22px;
  }

  .modal__subtitle {
    font-size: 16px;
  }

  .modal__text-description {
    font-size: 14px;
  }

  .modal__booking-button {
    padding: 0.7rem 1.2rem;
    font-size: 15px;
  }
}


@media (max-width: 768px) {
  .modal--container.active--modal {
    animation: slideIn 0.3s ease-out forwards;
  }

  @keyframes slideIn {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }

  .modal--container.active--modal .modal--content {
    transform: none;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal__description > * {
  animation: fadeIn 0.6s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  opacity: 0;
}

.modal__description > *:nth-child(1) { animation-delay: 0.1s; }
.modal__description > *:nth-child(2) { animation-delay: 0.2s; }
.modal__description > *:nth-child(3) { animation-delay: 0.3s; }
.modal__description > *:nth-child(4) { animation-delay: 0.4s; }
.modal__description > *:nth-child(5) { animation-delay: 0.5s; }
.modal__description > *:nth-child(6) { animation-delay: 0.6s; }
.modal__description > *:nth-child(7) { animation-delay: 0.7s; }

