.testimonials {
  padding-top: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.testimonials__list {
  display: flex;
  flex-direction: row;
  gap: 40px;
  width: 100%;
  justify-content: center;
}

.testimonials__testimonial {
  width: 365px;
  height: auto;
  min-height: 500px;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.5825892686843872px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  background-color: #D9D9D9;
  border-radius: 10px;
  padding-bottom: 30px;
}

.testimonial__photo {
  margin-top: 50px;
  margin-bottom: 25px;
  width: 120px;
  height: 120px;
  object-fit: contain;
}

.testimonial__nickname {
  position: relative;
  padding-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
}

.testimonial__nickname::after {
  display: block;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  width: 60px;
  background-color: #FFB46B;
  content: "";
}

.testimonial__description {
  margin-top: 21px;
  padding: 0 25px;  
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.testimonial__paragraph {
  margin: 20px 0;
  font-size: 14px;
  line-height: 1.6;
}

.testimonial__icon--start {
  position: absolute;
  top: 0;
  left: 25px;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
}

.testimonial__icon--end {
  position: absolute;
  bottom: 0;
  right: 25px;
  width: 20px;
  height: 20px;
  transform: translateY(50%);
}

@media screen and (max-width: 768px) {
  .testimonials {
    padding-top: 40px;
    padding-bottom: 60px;
  }

  .testimonials-swiper {
    width: 100%;
    padding: 0 20px 40px;
  }

  .testimonials-swiper .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  .testimonials__testimonial {
    width: 100%;
    max-width: 340px;
    min-height: 450px;
    margin: 0 auto;
  }

  .testimonial__photo {
    margin-top: 30px;
    margin-bottom: 20px;
    width: 100px;
    height: 100px;
  }

  .testimonial__description {
    padding: 0 20px;
  }

  .testimonial__paragraph {
    font-size: 13px;
    line-height: 1.5;
    margin: 15px 0;
  }

  .testimonial__icon--start {
    left: 15px;
    width: 16px;
    height: 16px;
  }

  .testimonial__icon--end {
    right: 15px;
    width: 16px;
    height: 16px;
  }

  .testimonials-swiper .swiper-pagination {
    bottom: 0;
  }

  .testimonials-swiper .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    background: rgba(255, 180, 107, 0.3);
    opacity: 1;
  }

  .testimonials-swiper .swiper-pagination-bullet-active {
    background: #FFB46B;
    opacity: 1;
  }

  .testimonials__list {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .testimonials__item {
    width: 100%;
  }
}
